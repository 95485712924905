import type { LabelProps as RadixLabelProps } from '@radix-ui/react-label';
import * as LabelPrimitive from '@radix-ui/react-label';
import clsx from 'clsx';
import type { FC } from 'react';

interface LabelProps extends RadixLabelProps {
  focusable?: boolean;
  className?: string;
}

const Label: FC<LabelProps> = ({
  children,
  focusable = false,
  className,
  ...rest
}) => (
  <LabelPrimitive.Root
    className={clsx('text-sm font-bold', className)}
    {...rest}
    tabIndex={focusable ? 0 : -1}
  >
    {children}
  </LabelPrimitive.Root>
);

export default Label;
